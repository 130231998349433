html,
body {
  margin: 0;
  min-height: 100vh;
  background: rgb(217, 217, 217);
  background: linear-gradient(
    0deg,
    rgba(217, 217, 217, 1) 0%,
    rgba(242, 242, 242, 1) 40%,
    rgba(242, 242, 242, 1) 100%
  );
}

a {
  color: #2648b8;
}

#page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
}

#page #back-btn-container {
  position: absolute;
  top: 1em;
  left: 1em;
}
#page #continue-btn-container {
  position: absolute;
  top: 1em;
  right: 1em;
}
#page #continue-btn-container div.animated-link::after {
  transform-origin: right;
}

#page > #content-container {
  margin-bottom: 1em;
  width: 90%;
}

#page > #content-container > #content-title {
  text-align: center;
  font-size: 2em;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

#page > #content-container > p {
  font-size: 1.1em;
  line-height: 1.5em;
  text-indent: 3em;
}

@media only screen and (min-width: 768px) {
  #page > #content-container {
    width: 60%;
  }
}

@media (prefers-color-scheme: dark) {
  html,
  body {
    background: #282c34;
    color: whitesmoke;
  }
  a {
    color: #61dafb;
  }
}
